<template>
  <div>
    <!--Filtros-->
    <suits-filter-card v-model="filter" session-key="all"/>

    <!--Colunas-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{$t('columns.title')}}</h5>
      </b-card-header>
      <b-card-body>
        <div class="text-center">
          <b-form-checkbox inline v-for="column in columns._options"
                           v-bind:id="`column-${column.key}`" v-bind:name="`column-${column.key}`"
                           v-model="columns[column.key]"
          >
            {{ $t(`columns.${column.label}`) }}
          </b-form-checkbox>
        </div>
      </b-card-body>
    </b-card>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Processos</h5>
      </b-card-header>
      <b-card-body>
        <suits-table ref="suitsTable" v-bind:filters="filter" v-bind:fields="columns" @selected="onSuitSelected">
          <template #table-top-actions="{selectedIds}">
            <div class="w-100 mb-1 ml-2" v-if="Object.keys(selectedIds).length > 0">
              <b-button size="sm" class="ml-1 d-inline-block" style="width: 250px" variant="outline-success" @click="sendToMonitoring(selectedIds, 'APPROACHING')">
                <i class="fa-regular fa-handshake"></i>
                {{$t('suitAction.sendToInteraction.full')}}
              </b-button>
              <b-button size="sm" class="ml-1 d-inline-block" style="width: 250px" variant="outline-secondary" @click="sendToMonitoring(selectedIds, 'MONITORING')">
                <i class="fa-solid fa-eye"></i>
                {{$t('suitAction.sendToMonitoring.full')}}
              </b-button>
              <b-button size="sm" class="ml-1 d-inline-block" style="width: 250px" variant="outline-danger" @click="sendToMonitoring(selectedIds, 'REMOVED')">
                <i class="fa-solid fa-xmark"></i>
                {{$t('suitAction.discard.full')}}
              </b-button>
            </div>
          </template>
          <!--Table-->
        </suits-table>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <suit-modal ref="suit-modal" read-only>
      <template #footer-actions="{suit}">
        <b-button class="ml-1 d-inline-block" style="width: 250px" variant="outline-success" @click="sendToMonitoring([suit.id], 'APPROACHING')">
          <i class="fa-regular fa-handshake"></i>
          {{$t('suitAction.sendToInteraction.base')}}
        </b-button>
        <b-button class="ml-1 d-inline-block" style="width: 250px" variant="outline-secondary" @click="sendToMonitoring([suit.id], 'MONITORING')">
          <i class="fa-solid fa-eye"></i>
          {{$t('suitAction.sendToMonitoring.base')}}
        </b-button>
        <b-button class="ml-1 d-inline-block" style="width: 250px" variant="outline-danger" @click="sendToMonitoring([suit.id], 'REMOVED')">
          <i class="fa-solid fa-xmark"></i>
          {{$t('suitAction.discard.base')}}
        </b-button>
      </template>
    </suit-modal>
  </div>
</template>

<script>
import SuitFilter from '@/model/SuitFilterRequest'
import mixinSuitViewBase from '@/components/mixin/mixinSuitViewBase'

export default {
  name: 'AllSuits',
  mixins:[mixinSuitViewBase],
  data() {
    let $this = this
    return {
      filter: {...SuitFilter(),monitoringStep:null},
      columns: {
        id: false,
        lastUpdate: true,
        complainant: true,
        defendant: true,
        section: true,
        subsection: true,
        sentence: true,
        sentenceAmount: true,
        keyWords: true,
        points: true,
        lastReview: true,
        _options: [
            { key: 'id', label: 'id' }, { key: 'lastUpdate', label: 'lastUpdate' }, { key: 'section', label: 'section' },
          { key: 'subsection', label: 'subsection' }, { key: 'complainant', label: 'complainant' }, { key: 'defendant', label: 'defendant' },
          { key: 'sentence', label: 'sentence' }, { key: 'sentenceAmount', label: 'sentenceEstimatedAmount' },
          { key: 'keyWords', label: 'keyWordsTotal' }, { key: 'points', label: 'points' },{ key: 'lastReview', label: 'lastReview'}]
      }
    }
  },

}
</script>

<style scoped>

</style>
